import { computed } from '@vue/composition-api'
import i18n from '@/libs/i18n'

export const types = computed(() => {
  return [
    { value: 'bool', title: i18n.t('behaviourTypes.bool') },
    { value: 'rating', title: i18n.t('behaviourTypes.rating') },
    { value: 'text', title: i18n.t('behaviourTypes.text') },
    { value: 'statement', title: i18n.t('behaviourTypes.statement') },
  ]
})

export const yesNoOptions = computed(() => {
  return [
    { value: true, title: i18n.t('message.yes') },
    { value: false, title: i18n.t('message.no') }
  ]
})

export const stateOptions = computed(() => {
  return [
    { value: true, title: i18n.t('completed') },
    { value: false, title: i18n.t('incomplete') }
  ]
})

export const actionPlanOptions = computed (() =>{
  return [
    { value: 'immediate', title: i18n.t('immediate') },
    { value: 'in_term', title: i18n.t('in_term') }
  ]
})

export const resultOptions = computed (() =>{
  return [
    { value: 'approved', title: i18n.t('approved') },
    { value: 'rejected', title: i18n.t('rejected') }
  ]
})

export const inTermApprovalStageOptions = computed (() =>{
  return [
    { value: 'analysis', title: i18n.t('analysisStage') },
    { value: 'corrective', title: i18n.t('correctiveStage') }
  ]
})

export const correctiveStageOptions = computed (() =>{
  return [
    { value: 'analysis', title: i18n.t('analysisStage') },
    { value: 'verification', title: i18n.t('verificationStage') }
  ]
})

export const immediateVerificationStageOptions = computed (() =>{
  return [
    { value: 'analysis', title: i18n.t('analysisStage') },
    { value: 'end', title: i18n.t('endStage') }
  ]
})

export const inTermVerificationStageOptions = computed (() =>{
  return [
    { value: 'analysis', title: i18n.t('analysisStage') },
    { value: 'corrective', title: i18n.t('correctiveStage') },
    { value: 'end', title: i18n.t('endStage') }
  ]
})

export const complianceCalculationFixedNumber = 10

export const complaintThemes = computed (() =>{
  return [
    { value: 'workplace_harassment', title: i18n.t('complaintThemes.workplace_harassment') },
    { value: 'sexual_harassment', title: i18n.t('complaintThemes.sexual_harassment') },
    { value: 'adulteration_of_financial_statements', title: i18n.t('complaintThemes.adulteration_of_financial_statements') },
    { value: 'misappropriation', title: i18n.t('complaintThemes.misappropriation') },
    { value: 'accounting_and_audit_matters', title: i18n.t('complaintThemes.accounting_and_audit_matters') },
    { value: 'incorrect_conduct_or_inappropriate_behavior', title: i18n.t('complaintThemes.incorrect_conduct_or_inappropriate_behavior') },
    { value: 'interest_conflict', title: i18n.t('complaintThemes.interest_conflict') },
    { value: 'corruption_among_private_individuals', title: i18n.t('complaintThemes.corruption_among_private_individuals') },
    { value: 'corruption_crime', title: i18n.t('complaintThemes.corruption_crime') },
    { value: 'bribery_crime', title: i18n.t('complaintThemes.bribery_crime') },
    { value: 'discrimination', title: i18n.t('complaintThemes.discrimination') },
    { value: 'workplace_discrimination', title: i18n.t('complaintThemes.workplace_discrimination') },
    { value: 'terrorist_financing', title: i18n.t('complaintThemes.terrorist_financing') },
    { value: 'fraud', title: i18n.t('complaintThemes.fraud') },
    { value: 'policy_violations', title: i18n.t('complaintThemes.policy_violations') },
    { value: 'money_laundering', title: i18n.t('complaintThemes.money_laundering') },
    { value: 'environmental_protection_law', title: i18n.t('complaintThemes.environmental_protection_law') },
    { value: 'misuse_of_resources', title: i18n.t('complaintThemes.misuse_of_resources') },
    { value: 'environment', title: i18n.t('complaintThemes.environment') },
    { value: 'receiving', title: i18n.t('complaintThemes.receiving') },
    { value: 'robbery_or_theft', title: i18n.t('complaintThemes.robbery_or_theft') },
    { value: 'occupational_health_and_safety', title: i18n.t('complaintThemes.occupational_health_and_safety') },
    { value: 'ti', title: i18n.t('complaintThemes.ti') },
    { value: 'use_of_privileged_or_confidential_information', title: i18n.t('complaintThemes.use_of_privileged_or_confidential_information') },
  ]
})

export const karinLawThemes = computed (() =>{
  return [
    { value: 'workplace_harassment', title: i18n.t('complaintThemes.workplace_harassment') },
    { value: 'sexual_harassment', title: i18n.t('complaintThemes.sexual_harassment') },
    { value: 'violence_at_work', title: i18n.t('complaintThemes.violence_at_work') },
  ]
})

export const complaintRelationshipOptions = computed (() =>{
  return [
    { value: 'collaborator', title: i18n.t('companyRelationships.collaborator') },
    { value: 'supplier', title: i18n.t('companyRelationships.supplier') },
    { value: 'customer', title: i18n.t('companyRelationships.customer') },
  ]
})

export const complaintTypesOptions = computed (() =>{
  return [
    { value: 'economicCrimesLaw', title: i18n.t('label.economicCrimesLaw') },
    { value: 'karinLaw', title: i18n.t('label.karinLaw') },
  ]
})